<form
	class="auth-form"
	[formGroup]="changePasswordForm"
	(ngSubmit)="onSubmit()"
>
	<header>
		<h1 class="auth-form__heading">Change Password</h1>
	</header>

	<meic-label
		class="auth-form__label"
		labelText="New Password"
	>
		<meic-password-field
			type="password"
			autocomplete="new-password"
			formControlName="newPassword"
			placeholder="Password"
		/>
	</meic-label>
	<meic-label
		class="auth-form__label"
		labelText="Confirm New Password"
	>
		<meic-password-field
			autocomplete="new-password"
			formControlName="newPasswordConfirmation"
			type="password"
			placeholder="Confirm Password"
		/>
	</meic-label>

	<footer class="auth-form__footer">
		<button
			class="button auth-form__submit-button"
			type="submit"
			[meicLoading]="isLoading$ | async"
		>
			Submit
		</button>
	</footer>
</form>
