<div
	class="notification-list__container"
	(click)="$event.stopPropagation()"
	(scroll)="onListScroll($event)"
>
	<div class="header">
		<span class="title">All Notifications</span>
		<button
			type="button"
			mat-button
			class="read-all-button"
			[meicLoading]="isMarkingAllAsRead$ | async"
			(click)="onMarkAllAsReadClick()"
		>
			Mark all as read
		</button>
	</div>
	@if (notifications.length > 0) {
		@for (item of notifications; track item.id) {
			<div
				class="content"
				(click)="onNotificationClick(item)"
			>
				<div class="message">
					<span [innerHTML]="item.message"></span>
					@if (item.readAt === null) {
						<div class="active-indicator__container">
							<div class="active-indicator"></div>
						</div>
					}
				</div>
				<div class="notify-date">{{ item.updatedAt | timeAgo }}</div>
			</div>
		}
	} @else {
		<p class="no-data">No notifications.</p>
	}
</div>
