import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpStatusCode,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { AppUrlsConfig } from '../services/app-urls.config';
import { NotificationMessages, NotificationService } from '../services/notification.service';
import { catchHttpErrorResponse } from '../utils/rxjs/catch-http-error-response';

/** Handle http errors and notify. */
@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {
	private readonly appUrlsConfigService = inject(AppUrlsConfig);

	private readonly notificationService = inject(NotificationService);

	/** @inheritdoc */
	public intercept(
		req: HttpRequest<unknown>,
		next: HttpHandler,
	): Observable<HttpEvent<unknown>> {
		const shouldIntercept = this.appUrlsConfigService.isApplicationUrl(req.url);
		if (!shouldIntercept) {

			// Do nothing.
			return next.handle(req);
		}

		return next.handle(req).pipe(
			catchHttpErrorResponse(httpError => {
				if (httpError.status === HttpStatusCode.PayloadTooLarge) {
					this.notificationService.notify(`Error: ${NotificationMessages.PAYLOAD_TOO_LARGE}`);
				}

				throw httpError;
			}),
		);
	}
}
