import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { User } from '@mei/common/core/models/user';
import { MasqueradeService } from '@mei/common/core/services/masquerade.service';
import { toggleExecutionState } from '@mei/common/core/utils/rxjs/toggle-execution-state';
import { WINDOW } from '@mei/common/core/utils/window-token';
import { LoadingDirective } from '@mei/common/shared/directives/loading.directive';
import { BehaviorSubject } from 'rxjs';

/** Masquerade bar. */
@Component({
	selector: 'meiw-masquerade-bar',
	templateUrl: './masquerade-bar.component.html',
	styleUrl: './masquerade-bar.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MatButtonModule, CommonModule, LoadingDirective],
})
export class MasqueradeBarComponent {
	/** User. */
	@Input({ required: true })
	public user!: User;

	private readonly masqueradeService = inject(MasqueradeService);

	private readonly window = inject(WINDOW);

	private destroyRef = inject(DestroyRef);

	/** Whether user is leaving masquerade or not. */
	protected readonly isLeaving$ = new BehaviorSubject<boolean>(false);

	/** Leave masquerade. */
	protected onLeave(): void {
		this.masqueradeService
			.leave()
			.pipe(toggleExecutionState(this.isLeaving$), takeUntilDestroyed(this.destroyRef))
			.subscribe(() => this.window.location.reload());
	}
}
