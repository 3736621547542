<div class="masquerade-bar">
	<span>
		Masquerading as
		<strong>{{ user.fullName }}</strong>
	</span>

	<button
		class="button"
		type="button"
		[meicLoading]="isLeaving$ | async"
		(click)="onLeave()"
	>
		Leave
	</button>
</div>
