import { z } from 'zod';

/** Password change schema. */
export const passwordChangeSchema = z.object({

	/** New password. */
	newPassword: z.string(),

	/** New password confirmation. */
	newPasswordConfirmation: z.string(),

	/** Token. */
	token: z.string(),

	/** Email. */
	email: z.string(),
});

/** Forgot password schema. */
export const forgotPasswordSchema = z.object({

	/** Token. */
	token: z.string(),
});

/** Password change data. */
export type PasswordChange = Readonly<z.infer<typeof passwordChangeSchema>>;

/** Forgot password. */
export type ForgotPassword = Readonly<z.infer<typeof forgotPasswordSchema>>;
