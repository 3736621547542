import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { AppUrlsConfig } from '../services/app-urls.config';

/** Adds cookies with withCredentials option to requests HTTP header. */
@Injectable()
export class CookiesCredentialInterceptor implements HttpInterceptor {

	private readonly appUrlsConfigService = inject(AppUrlsConfig);

	/** @inheritdoc */
	public intercept(
		req: HttpRequest<unknown>,
		next: HttpHandler,
	): Observable<HttpEvent<unknown>> {
		if (this.appUrlsConfigService.isApplicationUrl(req.url)) {
			const newReq = req.clone({
				withCredentials: true,
			});

			return next.handle(newReq);
		}

		// Do nothing.
		return next.handle(req);
	}
}
