import { importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppConfig } from '@mei/common/core/services/app.config';
import { provideEnvironmentNgxMask } from 'ngx-mask';

import { CookiesCredentialInterceptor } from '@mei/common/core/interceptors/cookies-credential.interceptor';

import { HttpErrorsInterceptor } from '@mei/common/core/interceptors/http-errors.interceptor';

import { appRoutes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { WebAppConfig } from './app/features/shared/web-app.config';
import { provideWebAppRoutes } from './app/features/shared/web-route-paths';

const httpInterceptorProviders = [
	{
		provide: HTTP_INTERCEPTORS,
		useClass: CookiesCredentialInterceptor,
		multi: true,
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: HttpErrorsInterceptor,
		multi: true,
	},
];

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(BrowserModule),
		...httpInterceptorProviders,
		{ provide: AppConfig, useClass: WebAppConfig },
		provideAnimations(),
		provideHttpClient(withInterceptorsFromDi()),
		provideRouter(appRoutes),
		provideWebAppRoutes(),
		provideEnvironmentNgxMask(),
	],
}).catch(err => console.error(err));
