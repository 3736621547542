import { map } from 'rxjs';
import { CanMatchFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UserService } from '@mei/common/core/services/user.service';
import { injectWebAppRoutes } from 'projects/web/src/app/features/shared/web-route-paths';

type AuthGuardParams = {

	/**
	 * Whether guard is configured for currently authorized user or not.
	 * If 'true', guard will prevent a current user from accessing a route if he is not authorized.
	 * If 'false', guard will prevent a current user from accessing a route if he is authorized.
	 */
	readonly isAuthorized: boolean;
};

/**
 * Auth guard.
 * @param isAuthorized Whether guard is configured for currently authorized user or not.
 */
export function authGuard({ isAuthorized }: AuthGuardParams): CanMatchFn {
	return () => {
		const userService = inject(UserService);
		const router = inject(Router);
		const webRoutesPath = injectWebAppRoutes();

		return userService.isAuthorized$.pipe(
			map(isUserAuthorized => {
				if (isAuthorized) {
					return isUserAuthorized ? true : router.parseUrl(webRoutesPath.auth.children.login.url);
				}

				return isUserAuthorized ? router.parseUrl(webRoutesPath.home.url) : true;
			}),
		);
	};
}
