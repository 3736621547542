import { z } from 'zod';

import { NotificationActionDto } from './notification-action.dto';

/** Notification dto schema. */
export const notificationDtoSchema = z.object({
	id: z.string(),
	quote_id: z.number(),
	created_by: z.number(),
	action: z.nativeEnum(NotificationActionDto),
	message: z.string(),
	read_at: z.string().nullable(),
	created_at: z.string(),
	updated_at: z.string(),
});

/** Notification mark as read params dto schema. */
export const notificationMarkAsReadParamsDto = z.object({
	ids: z.string(),
});

/** Notification DTO. */
export type NotificationDto = Readonly<z.infer<typeof notificationDtoSchema>>;

/** Notification mark as read params dto. */
export type NotificationMarkAsReadParamsDto = Readonly<z.infer<typeof notificationMarkAsReadParamsDto>>;
