import { Injectable, inject } from '@angular/core';
import {
	BehaviorSubject,
	EMPTY,
	Observable,
	concat,
	map,
	merge,
	of,
	scan,
	startWith,
	switchMap,
	tap,
} from 'rxjs';

import { BaseFilterParams } from '../models/base-filter-params';
import { WINDOW } from '../utils/window-token';
import { assertNonNullWithReturn } from '../utils/assert-non-null';
import { Pagination } from '../models/pagination';
import { filterNull } from '../utils/rxjs/filter-null';
import { toggleExecutionState } from '../utils/rxjs/toggle-execution-state';

/** Async pagination options. */
export type AsyncPaginationOptions<T> = Observable<T>;

type Comparator<T> = (item1: T, item2: T) => boolean;

type Patch<T> = {

	/** Observable that emits whenever we need to update a value of item in accumulated data. */
	readonly patch$: Observable<T>;

	/** Comparator for 2 items. */
	readonly comparator: Comparator<T>;
};

type PaginationActions<T> = {

	/** Observable that emits whenever we need to clear accumulated data. */
	readonly clear$?: Observable<unknown>;

	/** Patch item configuration. */
	readonly patch?: Patch<T>;
};

const emptyPagination = new Pagination({
	items: [],
	hasNext: false,
	hasPrev: false,
	totalCount: 0,
});

/**
 * Update pagination items (accumulate or reset).
 * @param newPage New page.
 * @param pageNumber Page number.
 */
const onUpdate = <T>(newPage: Pagination<T>) => (currentPage: Pagination<T>) =>
	new Pagination({
		hasNext: newPage.hasNext,
		hasPrev: newPage.hasPrev,
		totalCount: newPage.totalCount,
		items: newPage.hasPrev ? currentPage.items.concat(newPage.items) : newPage.items,
	});

/** Clear pagination items. */
const onClear = () => () => emptyPagination;

const onPatch = <T>(comparator: Comparator<T>) => (item: T) => (currentPage: Pagination<T>) =>
	new Pagination({
		hasNext: currentPage.hasNext,
		hasPrev: currentPage.hasPrev,
		totalCount: currentPage.totalCount,
		items: patchAccumulatedPaginationItem(currentPage, item, comparator).items,
	});

/** Infinite scroll pagination service. */
@Injectable()
export class InfiniteScrollPaginationService<T extends BaseFilterParams.Pagination> {
	private readonly _hasNextPage$ = new BehaviorSubject(false);

	private readonly _isPageLoading$ = new BehaviorSubject(false);

	/** Observable with filters. */
	public readonly filters$ = new BehaviorSubject<T | null>(null);

	private readonly window = inject(WINDOW);

	/**
	 * For scroll events of the current element.
	 * @param event Event.
	 */
	public onScroll(event: Event): void {
		const target = event.target as Document;
		const endOfPage = this.window.outerHeight + this.window.scrollY >= target.body.scrollHeight;
		if (endOfPage) {
			this.nextPage();
		}
	}

	/** Whether the page is loading or not. */
	public get isLoading$(): Observable<boolean> {
		return this._isPageLoading$.asObservable();
	}

	/**
	 * Sets initial filter params.
	 * @param filters Params to set.
	 */
	public setFilters(filters: T): void {
		this.filters$.next(filters);
	}

	/** Update page number to next. */
	public nextPage(): void {
		const currentFilters = assertNonNullWithReturn(this.filters$.getValue());

		if (this._isPageLoading$.value === false) {
			this.filters$.next({ ...currentFilters, pageNumber: currentFilters.pageNumber + 1 });
		}
	}

	/** Whether the pagination has next page. */
	public get hasNextPage$(): Observable<boolean> {
		return this._hasNextPage$.asObservable();
	}

	/**
	 * Accumulative pagination.
	 * @param options$ Pagination options.
	 * @param fetch Callback function for retrieving items.
	 * @param loading$ Observable that emits whenever page's load state changes.
	 * @param actions Accumulated pagination actions.
	 */
	public accumulativePagination<TData>(
		fetch: (options: T) => Observable<Pagination<TData>>,
		actions?: PaginationActions<TData>,
	): Observable<Pagination<TData> | null> {
		const page$ = this.paginate(this.filters$, fetch).pipe(filterNull());

		const onUpdate$ = page$.pipe(map(page => onUpdate(page)));

		const onClear$ = actions?.clear$ !== undefined ? actions.clear$.pipe(map(onClear)) : EMPTY;

		const onPatch$ = actions?.patch?.patch$ !== undefined ? actions.patch.patch$.pipe(map(onPatch(actions.patch.comparator))) : EMPTY;

		return merge(onUpdate$, onClear$, onPatch$).pipe(
			scan((acc: Pagination<TData>, handlePagination) => handlePagination(acc), emptyPagination),
			startWith(null),
		);
	}

	/**
	 * Allows paginating data based on provided object containing async properties for pagination.
	 * @param asyncOptions$ Async objects with pagination data.
	 * @param fetch Fetch function that accepts the pagination options.
	 * @example
	 * ```ts
	 * const searchString$ = new BehaviorSubject('');
	 * const $ = new BehaviorSubject('');
	 *
	 * ```
	 */
	private paginate<TData, O>(
		asyncOptions$: AsyncPaginationOptions<O | null>,
		fetch: (options: O) => Observable<Pagination<TData>>,
	): Observable<Pagination<TData> | null> {
		return asyncOptions$.pipe(
			filterNull(),
			switchMap(syncOptions =>
				concat(

					// First, reset the state
					of(null),

					fetch(syncOptions).pipe(
						tap(page => this._hasNextPage$.next(page.hasNext)),
						toggleExecutionState(this._isPageLoading$),
					),
				)),
		);
	}
}

const patchAccumulatedPaginationItem = <T>(
	accPagination: Pagination<T>,
	item: T,
	comparator: Comparator<T>,
): Pagination<T> => new Pagination({
	hasNext: accPagination.hasNext,
	hasPrev: accPagination.hasPrev,
	totalCount: accPagination.totalCount,
	items: accPagination.items.map(accItem => {
		if (comparator(accItem, item)) {
			return item;
		}
		return accItem;
	}),
});
