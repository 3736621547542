<div [meicLoading]="isLoading$ | async">
	@if (user$ | async; as user) {
		@if (user.isMasquerading) {
			<meiw-masquerade-bar [user]="user" />
		}
	}
	<meiw-header />
	<meiw-menu />
	<meic-side-menu [opened]="isOpen$ | async">
		<ng-template
			[cdkPortalOutlet]="menuPortal$ | async"
			menu
		></ng-template>
		<main class="main">
			<router-outlet />
		</main>
	</meic-side-menu>
</div>
