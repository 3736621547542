import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { Notification } from '@mei/common/core/models/notification/notification';
import { injectWebAppRoutes } from 'projects/web/src/app/features/shared/web-route-paths';
import { AppNotificationService } from '@mei/common/core/services/app-notification.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TimeAgoPipe } from '@mei/common/shared/pipes/time-ago.pipe';
import { BehaviorSubject } from 'rxjs';
import { toggleExecutionState } from '@mei/common/core/utils/rxjs/toggle-execution-state';
import { LoadingDirective } from '@mei/common/shared/directives/loading.directive';

/** Notification list component. */
@Component({
	selector: 'meiw-notification-list',
	templateUrl: './notification-list.component.html',
	styleUrl: './notification-list.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MatButtonModule, CommonModule, MatMenuModule, TimeAgoPipe, LoadingDirective],
})
export class NotificationListComponent {
	/** Notifications. */
	@Input({ required: true })
	public notifications!: readonly Notification[];

	/** Notification click. */
	@Output()
	public readonly notificationClick = new EventEmitter<void>();

	/** End of list. */
	@Output()
	public readonly endOfList = new EventEmitter<boolean>();

	private readonly quotingRoutes = injectWebAppRoutes().quoting;

	private readonly router = inject(Router);

	private readonly appNotificationService = inject(AppNotificationService);

	private readonly destroyRef = inject(DestroyRef);

	/** Whether all notifications are marking as read. */
	protected readonly isMarkingAllAsRead$ = new BehaviorSubject(false);

	/**
	 * Handle notification click.
	 * @param notification Notification.
	 */
	protected onNotificationClick(notification: Notification): void {
		this.appNotificationService
			.markAsRead([notification.id])
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				complete: () => {
					this.notificationClick.emit();
					this.router.navigateByUrl(this.quotingRoutes.children.quoteDetail.url({ quoteId: notification.quoteId }));
				},
			});
	}

	/** Handle mark all as read. */
	protected onMarkAllAsReadClick(): void {
		this.appNotificationService
			.markAllAsRead()
			.pipe(
				toggleExecutionState(this.isMarkingAllAsRead$),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe({
				complete: () => this.notificationClick.emit(),
			});
	}

	/**
	 * Handle on component scroll.
	 * @param event Scroll event.
	 **/
	protected onListScroll(event: Event): void {
		const target = event.target as HTMLDivElement;

		if (target) {
			// If the user has scrolled within 1px of the bottom, add more data
			const scrollThreshold = 1;
			const tableViewHeight = target.offsetHeight;
			const tableScrollHeight = target.scrollHeight;
			const scrollLocation = target.scrollTop;
			const scrollDownLimit = tableScrollHeight - tableViewHeight - scrollThreshold;
			if (scrollLocation > scrollDownLimit) {
				this.endOfList.emit(true);
			}
		}
	}
}
