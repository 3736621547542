import { ChangeDetectionStrategy, Component, DestroyRef, inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { Notification } from '@mei/common/core/models/notification/notification';
import { AppNotificationService } from '@mei/common/core/services/app-notification.service';
import { MatIconModule } from '@angular/material/icon';

import { DEFAULT_PAGINATION } from '@mei/common/core/models/base-filter-params';
import { Pagination } from '@mei/common/core/models/pagination';
import { map, Observable, shareReplay, tap } from 'rxjs';
import { filterNull } from '@mei/common/core/utils/rxjs/filter-null';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { InfiniteScrollPaginationService } from '@mei/common/core/services/infinite-scroll-pagination.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { NotificationListComponent } from '../notification-list/notification-list.component';

/** Notification component. */
@Component({
	selector: 'meiw-notification',
	templateUrl: './notification.component.html',
	styleUrl: './notification.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		MatButtonModule,
		CommonModule,
		MatMenuModule,
		MatIconModule,
		NotificationListComponent,
		MatProgressBarModule,
	],
	providers: [InfiniteScrollPaginationService],
})
export class NotificationComponent {
	/** Menu trigger. */
	@ViewChild('menuTrigger')
	public trigger: MatMenuTrigger | null = null;

	private readonly appNotificationService = inject(AppNotificationService);

	private readonly destroyRef = inject(DestroyRef);

	/** Paginated notifications. */
	protected readonly paginatedNotifications$: Observable<Pagination<Notification> | null>;

	/** Whether current user have unread notifications or not. */
	protected readonly haveUnreadNotifications$: Observable<boolean>;

	/** Infinite scroll service. */
	protected readonly infiniteScrollPaginationService = inject(InfiniteScrollPaginationService);

	/** Is loading notifications. */
	protected isLoadingNotifications$: Observable<boolean>;

	public constructor() {
		this.isLoadingNotifications$ = this.infiniteScrollPaginationService.isLoading$;
		this.infiniteScrollPaginationService.setFilters(DEFAULT_PAGINATION);
		this.paginatedNotifications$ = this.infiniteScrollPaginationService
			.accumulativePagination<Notification>(filterParams => this.appNotificationService.getAll(filterParams))
			.pipe(shareReplay({ bufferSize: 1, refCount: true }));

		this.haveUnreadNotifications$ = this.paginatedNotifications$.pipe(
			filterNull(),
			map(notifications => notifications.hasItems),
			shareReplay({ bufferSize: 1, refCount: true }),
		);
	}

	/** Handle notification click. */
	protected onNotificationClick(): void {
		if (this.trigger) {
			this.trigger.closeMenu();
			this.infiniteScrollPaginationService.setFilters(DEFAULT_PAGINATION);
		}
	}

	/**
	 * Handle end of notification list.
	 * @param isEndOfList Is end of list.
	 **/
	protected onEndOfList(): void {
		this.infiniteScrollPaginationService.hasNextPage$
			.pipe(
				tap(hasNextPage => {
					if (hasNextPage) {
						this.infiniteScrollPaginationService.nextPage();
					}
				}),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe();
	}
}
