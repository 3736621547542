import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterLink } from '@angular/router';
import { DialogService } from '@mei/common/core/services/dialog.service';
import { UserService } from '@mei/common/core/services/user.service';

import {
	ProfileDialogComponent,
	ProfileDialogData,
} from '../../../features/profile/profile-dialog/profile-dialog.component';
import { injectWebAppRoutes } from '../../../features/shared/web-route-paths';

import { NotificationComponent } from './components/notification/notification.component';

/** Header. */
@Component({
	selector: 'meiw-header',
	templateUrl: './header.component.html',
	styleUrl: './header.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		MatToolbarModule,
		MatIconModule,
		MatButtonModule,
		CommonModule,
		RouterLink,
		MatMenuModule,
		NotificationComponent,
	],
})
export class HeaderComponent {
	private readonly userService = inject(UserService);

	private readonly destroyRef = inject(DestroyRef);

	private readonly router = inject(Router);

	private readonly authRoutePaths = injectWebAppRoutes().auth;

	private readonly dialogService = inject(DialogService);

	/** Whether user is logged in . */
	protected isLoggedIn$ = this.userService.isAuthorized$;

	/** User. */
	protected user$ = this.userService.currentUser$;

	/** Auth routes. */
	protected readonly authRoutes = injectWebAppRoutes().auth;

	/** Handle logging out. */
	protected onLogout(): void {
		this.userService
			.logout()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				complete: () => this.router.navigateByUrl(this.authRoutePaths.children.login.url),
			});
	}

	/** Handle editing profile. */
	protected onEditProfile(): void {
		this.dialogService
			.openDialogWith<ProfileDialogComponent, ProfileDialogData, boolean>(ProfileDialogComponent, {
			title: 'Account Settings',
			size: 'extraNormal',
		})
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe();
	}
}
