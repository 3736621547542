import { Injectable, inject } from '@angular/core';
import { Observable, ignoreElements, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Notification } from '../models/notification/notification';
import { NotificationMapper } from '../mappers/notification.mapper';
import { NotificationDto, notificationDtoSchema } from '../dtos/notification/notification.dto';
import { createListResponseDtoSchema, ListResponseDto } from '../dtos/list-response.dto';
import { ListResponseMapper } from '../mappers/list-response.mapper';
import { Pagination } from '../models/pagination';
import { BaseFilterParams } from '../models/base-filter-params';
import { PaginationParamsMapper } from '../mappers/pagination.mapper';

import { secureParse } from '../utils/secureParse';

import { AppUrlsConfig } from './app-urls.config';

/** App notification service. */
@Injectable({
	providedIn: 'root',
})
export class AppNotificationService {
	private readonly appUrlsConfig = inject(AppUrlsConfig);

	private readonly httpClient = inject(HttpClient);

	private readonly notificationMapper = inject(NotificationMapper);

	private readonly listResponseMapper = inject(ListResponseMapper);

	private readonly paginationParamsMapper = inject(PaginationParamsMapper);

	/**
	 * Get all notifications.
	 * @param pagination Pagination.
	 **/
	public getAll(pagination: BaseFilterParams.Pagination): Observable<Pagination<Notification>> {
		const paramsDto = this.paginationParamsMapper.toDto(pagination);
		return this.httpClient
			.get<ListResponseDto<NotificationDto>>(this.appUrlsConfig.notifications.list, { params: paramsDto })
			.pipe(
				map(response => secureParse(response, createListResponseDtoSchema(notificationDtoSchema))),
				map(dto => this.listResponseMapper.fromDto(dto, this.notificationMapper.fromDto)),
			);
	}

	/**
	 * Mark notifications as read.
	 * @param ids Notification ids.
	 */
	public markAsRead(ids: Notification['id'][]): Observable<void> {
		const paramsDto = this.notificationMapper.toMarkAsReadParamDto(ids);
		return this.httpClient.put(this.appUrlsConfig.notifications.markAsRead, paramsDto).pipe(ignoreElements());
	}

	/** Mark all as read. */
	public markAllAsRead(): Observable<void> {
		return this.httpClient.put(this.appUrlsConfig.notifications.markAllAsRead, {}).pipe(ignoreElements());
	}
}
