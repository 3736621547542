<button
	mat-icon-button
	#menuTrigger="matMenuTrigger"
	[matMenuTriggerFor]="notificationList"
	class="notification-button"
	type="button"
>
	<mat-icon>notifications_none</mat-icon>
	@if (haveUnreadNotifications$ | async) {
		<span class="active-indicator"></span>
	}
</button>
<mat-menu
	#notificationList="matMenu"
	xPosition="before"
>
	@if (paginatedNotifications$ | async; as notifications) {
		<meiw-notification-list
			[notifications]="notifications.items"
			(notificationClick)="onNotificationClick()"
			(endOfList)="onEndOfList()"
		></meiw-notification-list>
		@if (isLoadingNotifications$ | async) {
			<mat-progress-bar
				mode="indeterminate"
				class="notification-progress-bar"
			></mat-progress-bar>
		}
	}
</mat-menu>
