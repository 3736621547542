<nav
	mat-tab-nav-bar
	[tabPanel]="tabPanel"
	class="menu"
>
	@for (link of links; track link.routerLink) {
		<a
			*ngIf="link.isAvailable$ ? (link.isAvailable$ | async) : true"
			mat-tab-link
			class="menu__link"
			[routerLink]="link.routerLink"
			routerLinkActive
			#linkStatus="routerLinkActive"
			[active]="linkStatus.isActive"
		>
			{{ link.title }}
		</a>
	}
</nav>
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
