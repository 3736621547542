<mat-toolbar class="header">
	<div class="header__logo-container">
		<img
			src="/assets/logo.svg"
			alt="Logo"
		/>
		<p class="header__title">MEI Rigging & Crating</p>
	</div>
	@if ((isLoggedIn$ | async) && (user$ | async); as user) {
		<div class="header__info">
			<meiw-notification></meiw-notification>
			<div class="info__user">
				<img
					class="user__avatar"
					src="/assets/avatar.svg"
					alt="User avatar"
				/>
				<p>{{ user.fullName }}</p>
			</div>
			<button
				mat-icon-button
				[matMenuTriggerFor]="menu"
				type="button"
			>
				<mat-icon>arrow_drop_down</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<button
					type="button"
					mat-menu-item
					(click)="onEditProfile()"
				>
					Profile
				</button>
				<button
					(click)="onLogout()"
					type="button"
					mat-menu-item
				>
					Logout
				</button>
			</mat-menu>
		</div>
	} @else {
		<a
			class="button"
			[routerLink]="authRoutes.children.login.url"
		>
			Login
		</a>
	}
</mat-toolbar>
