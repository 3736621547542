import { Injectable } from '@angular/core';

import { NotificationDto, NotificationMarkAsReadParamsDto } from '../dtos/notification/notification.dto';
import { Notification } from '../models/notification/notification';

import { NotificationActionDto } from '../dtos/notification/notification-action.dto';

import { NotificationAction } from '../models/notification/notification-action';

import { MapperFromDto } from './mappers';

/** Map notification action from dto to model. */
export const NOTIFICATION_ACTION_MAP_FROM_DTO: Readonly<Record<NotificationActionDto, NotificationAction>> = {
	[NotificationActionDto.Submitted]: NotificationAction.Submitted,
	[NotificationActionDto.Approved]: NotificationAction.Approved,
};

/** Notification mapper. */
@Injectable({
	providedIn: 'root',
})
export class NotificationMapper implements MapperFromDto<NotificationDto, Notification> {

	/** @inheritdoc */
	public fromDto(dto: NotificationDto): Notification {
		return {
			id: dto.id,
			quoteId: dto.quote_id,
			createdBy: dto.created_by,
			action: NOTIFICATION_ACTION_MAP_FROM_DTO[dto.action],
			message: dto.message,
			readAt: dto.read_at ? new Date(dto.read_at) : null,
			createdAt: new Date(dto.created_at),
			updatedAt: new Date(dto.updated_at),
		};
	}

	/**
	 * To mark as read param dto.
	 * @param ids Notification ids.
	 */
	public toMarkAsReadParamDto(ids: Notification['id'][]): NotificationMarkAsReadParamsDto {
		return {
			ids: ids.join(','),
		};
	}
}
