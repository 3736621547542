import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

/** Pipe for transforming date to ago format. Example: 2 hours ago. */
@Pipe({
	name: 'timeAgo',
	standalone: true,
})
export class TimeAgoPipe implements PipeTransform {
	private formatTimeUnit(value: number, unit: string): string {
		const roundedValue = Math.floor(value);
		return `${roundedValue} ${roundedValue === 1 ? unit : `${unit}s`} ago`;
	}

	/**
	 * Transform time different between given date and now. Return readable format. Ex: 20 minutes ago, 2 days ago,...
	 * @param date - Date to transform.
	 */
	public transform(date: Date | null): string {
		if (!date) {
			return '';
		}
		const now = DateTime.now();
		const givenDate = DateTime.fromJSDate(date);
		const diff = now.diff(givenDate, ['years', 'months', 'days', 'hours', 'minutes', 'seconds']).toObject();

		if (diff.years && diff.years >= 1) {
			return this.formatTimeUnit(diff.years, 'year');
		} else if (diff.months && diff.months >= 1) {
			return this.formatTimeUnit(diff.months, 'month');
		} else if (diff.days && diff.days >= 1) {
			return this.formatTimeUnit(diff.days, 'day');
		} else if (diff.hours && diff.hours >= 1) {
			return this.formatTimeUnit(diff.hours, 'hour');
		} else if (diff.minutes && diff.minutes >= 1) {
			return this.formatTimeUnit(diff.minutes, 'minute');
		}
		return 'Just now';
	}
}
